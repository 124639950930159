import { ResetService } from "@/decorators/resetService.decorator";
import { policyList } from "@/enums/permissions";
import { pusherEvents } from "@/enums/pusherEvents";
import { Location } from "@/interfaces/location";
import {
  PaymentMethod,
  RetailSettings,
  retailSettingsDefault
} from "@/interfaces/retailSettings";
import { EventBus } from "@/internal";
import { MenuSectionMetadata } from "@/metadata/menuSection";
import { messagesService } from "@/services/messages.service";
import { retailSettingsService } from "@/services/retailSettings.service";
import { BooleanCheck, Callback, PageNavAction } from "@/types/types";
import cloneDeep from "lodash/cloneDeep";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import InStoreSettingsComponent from "./inStoreSettings/InStoreSettings.component";
import OrderFulfillmentSettingsComponent from "./orderFulfillmentSettings/OrderFulfillmentSettings.component";
import PosOptionsComponent from "./posOptions/PosOptions.component";
import Template from "./RetailSettings.template.vue";
import RetailOptionsModelComponent from "./RetailSettingsModel/RetailOptionsModel.component";
const namespace = "AuthModule";

@Component({
  mixins: [Template],
  components: {
    InStoreSettingsComponent,
    PosOptionsComponent,
    OrderFulfillmentSettingsComponent
  },
  inject: ["$changes"]
})
@ResetService(retailSettingsService)
export default class RetailSettingsComponent extends Vue {
  @Getter("currentLocation", { namespace })
  public currentLocation!: Location;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Mutation("setCurrentRetailSettings", { namespace })
  public setRetailSettings!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public validator = this.$validator;
  public currentSettings: RetailSettings = cloneDeep(retailSettingsDefault);
  public loading = false;
  public isSaving = false;
  public tab: number = 2;
  public modelConfirm: boolean = false;
  public hasConfig: boolean = false;

  public get isMedical() {
    return this.currentLocation.location_type === "RETAIL_MEDICAL";
  }

  public async findSettings() {
    try {
      this.loading = true;
      this.currentSettings = await retailSettingsService.get();
      if (this.currentSettings.rx_number) {
        this.hasConfig = true;
      }

      this.setRetailSettings(this.currentSettings);
      // tell pos options to reset select image field
      EventBus.$emit("resetImage");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      this.loading = false;
      if (!this.currentSettings.location_id) {
        this.currentSettings.location_id = this.currentLocation.id;
        this.currentSettings.payment_methods!.map((method: PaymentMethod) => {
          method.location_id = this.currentLocation.id;
        });
      }
    }
  }
  public get checkValidateToSave() {
    if (!this.currentSettings.pharmacy_mode_enabled) {
      return true;
    } else if (
      this.currentSettings.pharmacy_mode_enabled &&
      this.currentSettings.rx_number &&
      (this.modelConfirm || this.hasConfig)
    ) {
      return true;
    }
  }

  public async saveSettings() {
    if (!this.hasConfig && this.currentSettings.rx_number) {
      this.modelConfirm = await this.$modals.load(
        RetailOptionsModelComponent,
        {
          size: "normal"
        },
        {
          msgModal: {
            title: this.$t(
              "retail_settings.rx_starting_number_model_warning_message"
            ),
            body: this.$t(
              "retail_settings.rx_starting_number_model_body_message"
            ),
            subbody: this.$t(
              "retail_settings.rx_starting_number_model_subbody_message"
            ),
            message: this.currentSettings.rx_number
          }
        }
      );
    } else {
      EventBus.$emit("onSave", true);
      this.tab = 2;
    }
    try {
      if (this.checkValidateToSave!) {
        this.isSaving = true;
        this.currentSettings.doctor_pharmacy_status_modified = 1;
        if (this.hasConfig) {
          delete this.currentSettings.rx_number;
        }
        const updatedRetail = await retailSettingsService.save(
          this.currentSettings
        );
        messagesService.renderSuccessMessage("settings_saved");
        this.setRetailSettings(updatedRetail);
        this.$store.dispatch(
          "MegaMenuModule/setMenuSections",
          MenuSectionMetadata()
        );
        this.$router.push({ name: "retail-options" });
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      this.isSaving = false;
    }
  }

  public cancel() {
    this.$router.back();
  }

  protected async mounted() {
    const retailSetting = await retailSettingsService.handleLoadingRetailSettingForFirsTimeForOklahoma();
    if (retailSetting) {
      this.currentSettings = retailSetting;
    }
    this.setPageNav({
      title: "retail_settings.title",
      isLoading: () => this.loading || this.isSaving,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            action: this.saveSettings,
            vuetifyProps: () => ({
              loading: this.loading || this.isSaving,
              disabled: !this.hasPermission(policyList.modifyRetailSettings),
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            action: this.cancel,
            vuetifyProps: () => ({
              loading: this.loading || this.isSaving,
              fab: true,
              small: true
            })
          }
        ]
      }
    });
    this.findSettings();
    this.$changes.watch(pusherEvents.retailSettings, this.findSettings);
  }
}
