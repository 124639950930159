import { statusOrder } from "@/interfaces/order";
import { RetailSettings } from "@/interfaces/retailSettings";
import { orderService } from "@/services/order.service";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./OrderFulfillmentSettings.template.vue";

@Component({
  mixins: [Template]
})
export default class OrderFulfillmentSettingsComponent extends Vue {
  @Prop({ required: true })
  public value!: RetailSettings;
  private orderStatus: boolean = false;

  get settings() {
    return cloneDeep(this.value);
  }

  public updateValues() {
    if (!this.settings.enable_in_store_order_fulfillment_flow) {
      this.settings.allow_order_to_bypass_in_store_order_fulfillment = 0;
      this.settings.print_pending_in_store_order_receipt_for_customer = 0;
    }
    this.$emit("input", this.settings);
  }

  public enableIsoof() {
    if (
      this.settings.enable_in_store_order_fulfillment_flow &&
      this.orderStatus
    ) {
      this.$modals
        .loadConfirmation(
          {
            title: "",
            text: "",
            html: `<p style=text-align:center;padding-top:1em;padding-bottom:2em;font-size:14px>${this.$t(
              "order_fulfillment.has_saved_orders"
            ).toString()}</p>`,
            acceptButton: "yes",
            cancelButton: "no"
          },
          {
            positionX: "center",
            positionY: "top",
            size: "normal"
          }
        )
        .then((confirm: boolean) => {
          if (confirm) {
            this.settings.delete_previous_orders = 1;
          } else {
            this.settings.delete_previous_orders = 0;
            this.settings.enable_in_store_order_fulfillment_flow = 0;
          }
        })
        .finally(this.updateValues);
    } else {
      this.settings.delete_previous_orders = 0;
    }
    this.updateValues();
  }

  public get isDisabled() {
    return !this.settings.enable_in_store_order_fulfillment_flow;
  }

  public async mounted() {
    const query = {
      "q[status_is_in]": [
        statusOrder.paid,
        statusOrder.pending,
        statusOrder.abandoned
      ]
    };
    const response = await orderService.get(query);
    this.orderStatus = response && response.length > 0;
  }
}
