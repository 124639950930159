import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./RetailOptionsModel.template.vue";

@Component({
  mixins: [Template]
})
export default class RetailOptionsModelComponent extends Vue {
  @Prop({ required: true })
  public msgModal!: {
    title: string;
    body: string;
    subbody: string;
    message: string;
  };
  public get rxNumber() {
    return this.msgModal!.message!.padStart(7, "0");
  }

  public acceptModal() {
    this.$emit("resolve", true);
  }

  public closeModal() {
    this.$emit("reject", false);
  }
}
