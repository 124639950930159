import { RetailSettings } from "@/interfaces/retailSettings";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./InStoreSettings.template.vue";

@Component({
  mixins: [Template]
})
export default class InStoreSettingsComponent extends Vue {
  @Prop({ required: true })
  public settings!: RetailSettings;
  @Prop({ default: false })
  public disableInStore!: boolean;
}
