import SelectImageComponent from "@/components/sharedComponents/selectImage/SelectImage.component";
import { policyList } from "@/enums/permissions";
import { Location } from "@/interfaces/location";
import { RetailSettings } from "@/interfaces/retailSettings";
import { EventBus } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { messagesService } from "@/services/messages.service";
import { pmpIntegrationService } from "@/services/pmpIntegration.service";
import { retailSettingsService } from "@/services/retailSettings.service";
import { BooleanCheck } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import orderBy from "lodash/orderBy";
import { Component, Prop, Vue } from "vue-property-decorator";
// @ts-ignore
import draggable from "vuedraggable";
import { Getter } from "vuex-class";
import InStoreSettingsComponent from "../inStoreSettings/InStoreSettings.component";
import Template from "./PosOptions.template.vue";

@Component({
  mixins: [Template],
  components: { draggable, SelectImageComponent, InStoreSettingsComponent }
})
export default class PosOptionsComponent extends Vue {
  @Prop({ required: true })
  public value!: RetailSettings;
  public dropdownType: string | null = null;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public noPermission: boolean = false;
  public pmpIntegrationVal: boolean = false;
  public hasConfig: boolean = false;
  public onSavedError: boolean = false;
  public rxNumberKey: number = 1;
  public items = [
    {
      id: "SECONDARY_ID",
      name: i18n.t("inventory_settings_view.secondary_id")
    },
    { id: "NDC_NUMBER", name: i18n.t("inventory_settings_view.ndc_number") },
    { id: "NONE", name: i18n.t("inventory_settings_view.none") }
  ];

  get model() {
    return cloneDeep(this.value);
  }

  public get isMedical() {
    return this.currentLocation.location_type === "RETAIL_MEDICAL";
  }

  public imagePriorityArray: Array<{
    name: string;
    priority: number;
  }> = [];

  public pharmacyModeUpdated(newValue: boolean) {
    if (newValue) {
      this.model.doctor_tracking_enabled = 1;
    }
    this.updateSettings();
  }

  public setImage(imageFile: File) {
    this.model.default_image_product = imageFile;
    this.updateSettings();
  }

  public async updateSettings() {
    this.$emit("input", this.model);
    if (!this.model.pharmacy_mode_enabled && !this.hasConfig) {
      this.model.rx_number = "";
      this.onSavedError = false;
    }
  }
  public get hasrxNumber() {
    if (this.hasConfig) {
      return true;
    } else {
      if (!this.model.pharmacy_mode_enabled && !this.hasConfig) {
        return true;
      }
    }
  }

  public validateInput(value: string) {
    if (!/^[0-9]{0,16}?$/.test(value)) {
      this.rxNumberKey += 1;
      this.$nextTick(() => {
        (this.$refs.quantity_input_ as HTMLElement).focus();
      });
    } else {
      this.model.rx_number = value;
      this.updateSettings();
    }
  }

  public validateSpecialCharacter(value: KeyboardEvent) {
    if (!/^[0-9]?\.?$/.test(value.key) || value.key === ".") {
      value.preventDefault();
    }
  }

  public async mounted() {
    this.noPermission = !this.hasPermission(policyList.modifyRetailSettings);
    EventBus.$on("onSave", (value: boolean) => {
      this.onSavedError = value;
      setTimeout(() => {
        this.onSavedError = false;
      }, 1500);
    });
    const config = await retailSettingsService.get();
    if (config.rx_number) {
      this.hasConfig = true;
    }

    this.imagePriorityArray = [
      {
        name: "product_image",
        priority: this.value.product_images_priority || 1
      },
      {
        name: "brand_image",
        priority: this.value.brand_images_priority || 2
      },
      {
        name: "strain_image",
        priority: this.value.strain_images_priority || 3
      }
    ];
    this.imagePriorityArray = orderBy(
      this.imagePriorityArray,
      ["priority"],
      ["asc"]
    );

    this.model.stock_identification_type_in_pos =
      this.value.stock_identification_type_in_pos !== null
        ? this.value.stock_identification_type_in_pos
        : this.currentLocation.state!.code === "CT"
        ? "NDC_NUMBER"
        : "SECONDARY_ID";
    if (this.currentLocation.state!.code !== "CT") {
      this.items = this.items.filter(item => item.id !== "NDC_NUMBER");
    }
    this.getPMPconfiguration();
  }

  public changedArray(newPriorityArray: Array<{ name: string }>) {
    newPriorityArray.forEach((priority, index) => {
      let key;
      if (priority.name === "product_image") {
        key = "product_images_priority";
      } else if (priority.name === "brand_image") {
        key = "brand_images_priority";
      } else {
        key = "strain_images_priority";
      }

      this.model[key] = index + 1;
    });

    this.updateSettings();
  }
  public async getPMPconfiguration() {
    try {
      const config = await pmpIntegrationService.getCurrentPmpFtpConfig([
        +this.currentLocation.id!
      ]);
      if (config.length > 0) {
        if (config[0].status) {
          this.pmpIntegrationVal = true;
        } else {
          this.pmpIntegrationVal = false;
        }
      } else {
        this.pmpIntegrationVal = false;
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
}
